import styles from "./tab-components.module.scss"
import React, { Component } from "react"

//-- Tabs container with tab links
export class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex:
        typeof sessionStorage !== "undefined"
          ? Number(sessionStorage.getItem("activeTabIndex") || 0)
          : 0,
    }
  }
  render() {
    const { children } = this.props
    return (
      <div>
        <nav
          className={
            this.props.invert === true
              ? `${styles.navlinks} ${styles.invert}`
              : styles.navlinks
          }>
          {children.map((el, i) => (
            <button
              key={i}
              onClick={() => {
                this.setState({ activeTabIndex: i })
                sessionStorage.setItem("activeTabIndex", i)
              }}
              className={
                this.state.activeTabIndex === i
                  ? `${styles.navlinks__item} ${styles.active}`
                  : styles.navlinks__item
              }>
              {el.props.title}
            </button>
          ))}
        </nav>
        <div>{children[this.state.activeTabIndex]}</div>
      </div>
    )
  }
}

// Tab Pane
export class TabPane extends Component {
  render() {
    return <div className="tabContent">{this.props.children}</div>
  }
}

import styles from "./start-demo.module.scss"
import checkStyles from "./../../styles/commons/circlechecks.module.scss"
import React from "react"
import { Link } from "gatsby"
import { ENV_CONSOLE_BASE_URL } from "../../utils/secrets"

const StartDemo = ({ props }) => {
  let title
  let benefits
  let single

  if (props) {
    title = props.title
    benefits = props.benefits
    single = props.singleline
  }

  return (
    <section className={styles.demo}>
      <div className={`container`}>
        <div className="row">
          <div className="col-sm-12">
            <div className={styles.demo__wrapper}>
              <div className={styles.demo__listWrapper}>
                <h2 className={styles.demo__title}>
                  {title || "Ready to Start for Free?"}
                </h2>
                <div className={styles.demo__lists}>
                  {!benefits && (
                    <ul
                      className={`list-unstyled mb-0 ${checkStyles.circlechecks}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Global app delivery network
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Multi-cloud networking
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Web app security
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Secure K8s gateway
                      </li>
                    </ul>
                  )}
                  {benefits && (
                    <ul
                      className={`list-unstyled mb-0 ${
                        checkStyles.circlechecks
                      } ${single ? styles.nochecks : ""}`}>
                      {benefits.map((benefit, i) => (
                        <li key={i} className={checkStyles.circlechecks__item}>
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className={styles.demo__btnWrapper}>
                <a
                  aria-label="Get Started"
                  href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                  className={styles.demo__button}>
                  Get Started
                </a>
                <Link
                  aria-label="Ask an Expert link"
                  to={"/demo"}
                  className={`${styles.demo__button} ${styles.demo__buttonOutline}`}>
                  Ask an Expert
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StartDemo

import styles from "../../styles/voltconsole.module.scss"
import cblockStyles from "./../../styles/commons/cblock.module.scss"
import checkStyles from "./../../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../../../src/components/layout/layout"
import { graphql, useStaticQuery, Link } from "gatsby"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import { Tabs, TabPane } from "../../components/tab-components/tab-components"
import StartDemo from "../../components/start-demo/start-demo"
import ArrowSvg from "../../components/svg/arrow"
import { ENV_CONSOLE_BASE_URL } from "../../utils/secrets"

const VoltConsolePage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__voltconsole" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  const scrollPage = elId => {
    const element = document.getElementById(elId)
    const header = document.getElementById("header")
    const distance = element.offsetTop - header.offsetHeight
    window.scrollTo({
      top: distance,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/voltconsole"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Header */}
      <section className={styles.header}>
        <div className="container volterra-container d-flex flex-column flex-lg-row">
          <div className={`row ${styles.header__wrapper}`}>
            <h1
              className={`${styles.header__title} ${styles.header__titleHighlighted}`}>
              VoltConsole<span>&trade;</span>
            </h1>
            <h2 className={styles.header__title}>
              Single pane of glass for distributed apps & infrastructure
            </h2>
            <p className={styles.header__description}>
              A SaaS-based operations and observability portal to manage
              infrastructure and apps across multi-cloud and edge, provide early
              warnings and generate actionable business insights
            </p>
            <div className={styles.header__cta}>
              <Link
                to={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                aria-label="Start for Free"
                className={"btn btn-primary " + styles.header__btn}>
                Start for Free
              </Link>
            </div>
          </div>
          <div className={styles.header__imageWrapper}>
            <img
              src="/img/products/voltconsole.png"
              alt="VoltConsole"
              className={styles.header__image}
            />
          </div>
        </div>
      </section>

      {/* Key Features */}
      <section
        className={
          "pb-0 " + [cblockStyles.cblock, styles.cblock__keyfeats].join(" ")
        }>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Key Features</h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <img
                        src="/img/products/observability-icon.svg"
                        alt="Spyglass icon"
                      />
                      <h5>Observability</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Monitor health and manage the end-to-end lifecycle of
                        the network and app services deployed in hybrid cloud,
                        multi-cloud, our global network or edge locations
                      </p>
                      <button
                        className={styles.topfeatsslider__link}
                        aria-label="Observability"
                        onClick={() => {
                          scrollPage("observRef")
                        }}>
                        Read more
                        <ArrowSvg className="arrow" />
                      </button>
                      <img
                        src="/img/products/voltconsole-key-observability.jpg"
                        alt="Screenshot showing Site map"
                        className={
                          "img-fluid " + `${styles.topfeatsslider__imginner}`
                        }
                      />
                    </div>
                  </div>

                  {/* Item */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <img
                        src="/img/products/security-icon-alt.svg"
                        alt="Padlock icon"
                      />
                      <h5>Security</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Control and get visibility of multi-layer security
                        services to protect the network, apps, APIs and critical
                        secrets like keys, certificates, and passwords
                      </p>
                      <button
                        className={styles.topfeatsslider__link}
                        aria-label="Security"
                        to={"/"}
                        onClick={() => {
                          scrollPage("securiRef")
                        }}>
                        Read more
                        <ArrowSvg className="arrow" />
                      </button>
                      <img
                        src="/img/products/voltconsole-key-security.jpg"
                        alt="Screenshot showing Virtual hosts security dashboard"
                        className={
                          "img-fluid " + `${styles.topfeatsslider__imginner}`
                        }
                      />
                    </div>
                  </div>

                  {/* Item */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <img
                        src="/img/products/intelligence-icon.svg"
                        alt="Brain icon"
                      />
                      <h5>Intelligence</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Reduce the operational burden through eyes-on-glass
                        monitoring, proactive alerts + AI-powered anomaly
                        detection, and audit changes for compliance
                      </p>
                      <button
                        className={styles.topfeatsslider__link}
                        aria-label="Intelligence"
                        to={"/"}
                        onClick={() => {
                          scrollPage("intelliRef")
                        }}>
                        Read more
                        <ArrowSvg className="arrow" />
                      </button>
                      <img
                        src="/img/products/voltconsole-key-intelligence.jpg"
                        alt="Screenshot showing Metrics analytics"
                        className={
                          "img-fluid " + `${styles.topfeatsslider__imginner}`
                        }
                      />
                    </div>
                  </div>

                  {/* Item */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <img
                        src="/img/products/operations-icon.svg"
                        alt="Operations icon"
                      />
                      <h5>Operations</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Efficiently operate your distributed cloud with granular
                        RBAC, enhanced collaboration across operating teams,
                        responsive support ticketing and detailed usage-linked
                        billing
                      </p>
                      <button
                        className={styles.topfeatsslider__link}
                        aria-label="Operations"
                        to={"/"}
                        onClick={() => {
                          scrollPage("opsRef")
                        }}>
                        Read more
                        <ArrowSvg className="arrow" />
                      </button>
                      <img
                        src="/img/products/voltconsole-key-operations.jpg"
                        alt="Screenshot showing namespace management"
                        className={
                          "img-fluid " + `${styles.topfeatsslider__imginner}`
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <img
                        src="/img/products/voltconsole-key-observability.jpg"
                        alt="Screenshot showing Site map"
                        className={"img-fluid"}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <img
                        src="/img/products/voltconsole-key-security.jpg"
                        alt="Screenshot showing Virtual hosts security dashboard"
                        className={"img-fluid"}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <img
                        src="/img/products/voltconsole-key-intelligence.jpg"
                        alt="Screenshot showing Metrics analytics"
                        className={"img-fluid"}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <img
                        src="/img/products/voltconsole-key-operations.jpg"
                        alt="Screenshot showing namespace management"
                        className={"img-fluid"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Observability */}
      <section
        className={[
          cblockStyles.cblock,
          cblockStyles.cblock__vconsole,
          cblockStyles.cblock__bubbles__rm,
        ].join(" ")}
        id="observRef">
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Observability</h2>
            <p>
              Monitor health and manage the end-to-end lifecycle of the network
              and app services deployed in hybrid cloud, multi-cloud, our global
              network or edge locations
            </p>
          </div>
          <div className={cblockStyles.cblock__body}>
            <Tabs className={styles.tabsblock}>
              <TabPane title="Infrastructure">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-observability-infra.jpg"
                      alt="Screenshot showing Site dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Infrastructure</h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Detailed view of each individual site running your
                        VoltMesh or VoltStack service — in the cloud, network or
                        edge.
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Granular visibility of system health, app deployments,
                        network + security services, connectivity metrics and
                        resource consumption
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Lifecycle management of the distributed system --
                        deployment, provisioning, upgrades and decommission
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Applications">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-observability-apps.jpg"
                      alt="Screenshot showing fleets list"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Applications</h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Aggregate view of app deployed across clusters in one or
                        multiple clouds, at the edge, or on Volterra’s network
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Faster troubleshooting of app failures via events and
                        console access into the container from VoltConsole
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Simplified operation of a high volume of app clusters
                        via fleet-wide monitoring and actions — e.g. resource
                        consumption and upgrades
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Load balancers and proxies">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-observability-lb-proxy.jpg"
                      alt="Screenshot showing virtual hosts dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      Load balancers and proxies
                    </h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Granular visibility of load balancers and proxies
                        including end-to-end latency, origin servers, top
                        clients and errors
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Monitoring of traffic metrics such as throughput,
                        request/response rate and geo-location
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Realtime and historical trends of requests, alerts and
                        errors with individual request-level detail for faster
                        troubleshooting
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Service mesh">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-observability-servicemesh.jpg"
                      alt="Screenshot showing Service mesh graph"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Service Mesh</h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Birds-eye view of the network- and API-level
                        connectivity of apps within a single cluster or across
                        multiple clusters
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Health of individual service or service-to-service
                        communication - latency, request/response rate, errors,
                        etc
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Historical trends of requests and errors for
                        service-to-service communication with the ability to
                        inspect individual requests
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      {/* Security */}
      <section
        className={[
          cblockStyles.cblock,
          cblockStyles.cblock__vconsole,
          cblockStyles.cblock__invert,
          cblockStyles.cblock__bubbles__tlmd,
        ].join(" ")}
        id="securiRef">
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Security</h2>
            <p>
              Control and get visibility of multi-layer security services to
              protect the network, apps, APIs and critical secrets like keys,
              certificates, and passwords
            </p>
          </div>
          <div className={cblockStyles.cblock__body}>
            <Tabs invert={true} className={styles.tabsblock}>
              <TabPane title="API Discovery & Control">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-security-api-discovery.jpg"
                      alt="Screenshot showing API endpoints browser"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      API Discovery & Control
                    </h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        AI/ML-powered discovery of APIs along with probabilistic
                        distribution of request and response sizes, latency, etc
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Fast identification of anomalous behavior and security
                        attacks when API access falls outside of the
                        machine-generated profile
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Automated generation of app-layer policies with the
                        ability to configure them to match HTTP headers, API
                        parameters, etc
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Application Security">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-security-app-security.jpg"
                      alt="Screenshot showing virtual hosts security dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      Application Security
                    </h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        App firewall to protect against well-known threat
                        vectors based on OWASP, Volterra security research and
                        custom rule sets
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Granular visibility into firewall rules, categorized by
                        severity, origin and type of attacks
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Reduction in false positives by adjusting anomaly
                        thresholds and risk levels along with the flexibility to
                        switch from blocking to alerting mode
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Network Security">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-security-net-security.jpg"
                      alt="Screenshot showing security events analytics"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      Network Security
                    </h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Network firewall to enable the NetOps team to define
                        policies based on IP addresses, BGP ASNs, protocols and
                        ports
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Distributed control plane to define intent and apply it
                        across multiple sites in the cloud, edge or across the
                        Volterra network
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Granular visibility into network firewall and policy
                        hits via the site dashboard
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Secrets & Key Management">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-security-key-management.jpg"
                      alt="Screenshot showing Secret Policy editor"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      Secrets & Key Management
                    </h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Definition and control of access to app secrets, keys
                        and passwords via policy
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        KMS for a single control point to manage cryptographic
                        keys and define policies for crypto operations such as
                        encryption/decryption
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Flexible policy definition for matching app name and
                        customizable tags
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      {/* Intelligence */}
      <section
        className={[
          cblockStyles.cblock,
          cblockStyles.cblock__vconsole,
          cblockStyles.cblock__bubbles__tr,
        ].join(" ")}
        id="intelliRef">
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Intelligence</h2>
            <p>
              Reduce the operational burden through eyes-on-glass monitoring,
              proactive alerts + AI-powered anomaly detection, and audit changes
              for compliance.
            </p>
          </div>
          <div className={cblockStyles.cblock__body}>
            <Tabs className={styles.tabsblock}>
              <TabPane title="Eyes-on-glass monitoring">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-intelligence-monitoring.jpg"
                      alt="Screenshot showing Site dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      Eyes-on-glass monitoring
                    </h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        End-to-end monitoring of VoltMesh and VoltStack across
                        sites in the cloud, edge and the Volterra network
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Dashboards to measure key performance indicators such as
                        connectivity status, app request/error rates, security
                        events, system alerts, etc
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Aggregated operational view of distributed apps, system
                        health and resources consumed
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Early warnings">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-intelligence-warnings.jpg"
                      alt="Screenshot showing fleets list"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Early warnings</h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Early warnings via continuous monitoring and generation
                        of alerts — system-wide or per namespace
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Categorization of alerts as critical, major and minor
                        along with grouping by functionality - app, security,
                        networking, etc
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Integration of alerts into your existing notification
                        and ticketing systems such as ServiceNow, PagerDuty,
                        Slack, etc
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="AI/ML-powered insights">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-intelligence-insights.jpg"
                      alt="Screenshot showing virtual hosts dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      AI/ML-powered insights
                    </h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Continuous AI/ML-based analytics of multiple metrics to
                        baseline normal patterns and identify anomalous
                        deviations
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Anomaly detection on multiple metrics such as individual
                        site metrics, site-to-site traffic, app metrics,
                        app-to-app traffic, etc
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Continuous profiling of apps’ API usage to baseline
                        normal patterns of request/response sizes, rates,
                        throughput and latency
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Audit history">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-8">
                    <img
                      src="/img/products/voltconsole-intelligence-audit-history.jpg"
                      alt="Screenshot showing Service mesh graph"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Audit history</h3>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        Granular audit logs of every operation performed on the
                        system via console, APIs or CLI
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Detailed audit logs that capture timestamp, user,
                        namespace, method (GET, PUT etc) along with the request
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Changes to any configuration object are visible for
                        faster troubleshooting
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      {/* Operations */}
      <section
        className={[
          cblockStyles.cblock,
          cblockStyles.cblock__vconsole,
          cblockStyles.cblock__invert,
          cblockStyles.cblock__bubbles__tlmd,
        ].join(" ")}
        id="opsRef">
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Operations</h2>
            <p>
              Efficiently operate your distributed cloud with granular RBAC,
              enhanced collaboration across operating teams, responsive support
              ticketing and detailed usage-linked billing
            </p>
          </div>
          <div className={cblockStyles.cblock__body}>
            <Tabs invert={true} className={styles.tabsblock}>
              <TabPane title="Identity & access management">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-operations-identity.jpg"
                      alt="Screenshot showing API endpoints browser"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>
                      Identity & access management
                    </h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Role-based access control (RBAC) gives administrators
                        the ability to control user access — system-wide, per
                        namespace or individual APIs
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Administrators can define roles to control operations
                        that are allowed across the system and can assign users
                        to them
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        SSO using OIDC allows seamless integration with
                        enterprise access control providers like Azure Active
                        Directory, Okta, G Suite, etc
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Multi-tenancy">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-operations-multi-tenancy.jpg"
                      alt="Screenshot showing virtual hosts security dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Multi-tenancy</h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Collaboration across teams (NetOps, SecOps, DevOps)
                        through self-service using system, shared and per
                        project namespaces
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        NetOps can manage the entire system via the system
                        namespace and enforce organization-wide network and
                        security policies
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        SecOps can define policies in shared or system namespace
                        and have it automatically enforce them for every project
                        or app
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Support">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-operations-support.jpg"
                      alt="Screenshot showing security events analytics"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Support</h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Support tickets can be directly opened within
                        VoltConsole and collaborated on with Volterra’s global
                        support team
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Volterra’s support line can be called for faster
                        resolution if required (depending on the level of
                        support plan purchased)
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Self-service support resources include quick-start
                        guides, how-to instructions and detailed technical and
                        API documentation
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane title="Cost visibility">
                <div
                  className={
                    "row flex-xl-row-reverse " + styles.tabsblock__content
                  }>
                  <div className="col-xl-8 text-xl-right">
                    <img
                      src="/img/products/voltconsole-operations-cost-visibility.jpg"
                      alt="Screenshot showing Secret Policy editor"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-4">
                    <h3 className={styles.tabsblock__title}>Cost visibility</h3>
                    <ul
                      className={`list-unstyled ${checkStyles.circlechecks} ${checkStyles.circlechecks__invert}`}>
                      <li className={checkStyles.circlechecks__item}>
                        Granular visibility of VoltMesh and VoltStack usage
                        along with invoice and transaction history
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Detailed historical usage records (per namespace) enable
                        strong cost control by determining normal usage and
                        identifying deviations
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        Billing information such as payment method is easily
                        updatable
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      <StartDemo />
    </Layout>
  )
}

export default withAppContext(VoltConsolePage)
